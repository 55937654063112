// open-close init
export default function initHasDropDown() {
  const holder = document.querySelector('.menu-list');
  const items = holder.querySelectorAll('li');
  items.forEach(function (item) {
    if (item.querySelector('ul')) {
      item.classList.add('has-dropdown');
      item.querySelector('a').classList.add('has-dropdown-a');
    }
  });
}
