
export default function initTab() {
  const links = document.querySelectorAll('.tabset a');
	const content = document.querySelector('.tab__content');
	

	links.forEach((link)=> {
		link.addEventListener('click', loadTabs)
	});

	// load tabs function
	function loadTabs(e) {
		e.preventDefault();
		links.forEach((link)=> {
			link.classList.remove('active');
		});
		e.path[0].classList.add('active');
		Array.from(content.children).forEach((cont) => {
			cont.classList.remove('active');
		});
		console.log(this.getAttribute('href'));
		document.querySelector(this.getAttribute('href')).classList.add('active');
	}
}