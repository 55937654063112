
export default function initMobileNav() {
  var navLinkList = document.getElementsByClassName('main-nav__opener');
  var activeClass = 'nav-active';
  
  for(var i = 0; i < navLinkList.length; i++) {
    var navLink = navLinkList[i];
    var hasOpened = false;
    
    navLink.addEventListener('click', function(event) {
      event.preventDefault();
      
      if(hasOpened)
      document.body.classList.remove(activeClass);
      else
      document.body.classList.add(activeClass);
      
      hasOpened = !hasOpened;
    });
  }
  
}