import Swiper from 'swiper';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';


SwiperCore.use([Navigation, Pagination]);

export default function initSwiper() {
  if (document.querySelector(".img-block")) {
    const sliders = document.querySelectorAll(".img-block");
    sliders.forEach(function (slider, index) {
      let mode = null;
      const sliderHtml = slider.innerHTML;
      
      const resizeHandler = () => {
        if (window.innerWidth > 767) {
          if (mode != 'desktop') {
            slider.innerHTML = sliderHtml;
            mode = 'desktop';
          }
        } else {
          if (mode != 'mobile') {
            new Swiper(slider, {
              loop: false,
              spaceBetween: 15,
            });
            mode = 'mobile';
          }
        }
      }
      resizeHandler();
      window.addEventListener('resize', resizeHandler);
      
    });
    
    
  }
  
  if (document.querySelector(".icon-block")) {
    const sliders = document.querySelectorAll(".icon-block");
    sliders.forEach(function (slider, index) {
      let mode = null;
      const sliderHtml = slider.innerHTML;
      
      const resizeHandler = () => {
        if (window.innerWidth > 767) {
          if (mode != 'desktop') {
            slider.innerHTML = sliderHtml;
            mode = 'desktop';
          }
        } else {
          if (mode != 'mobile') {
            new Swiper(slider, {
              loop: false,
              spaceBetween: 15,
              pagination: {
                el: '.swiper-pagination',
              },
            });
            mode = 'mobile';
          }
        }
      }
      resizeHandler();
      window.addEventListener('resize', resizeHandler);
      
    });
    
    
  }
  
  if (document.querySelector(".life")) {
    const sliders = document.querySelectorAll(".life");
    sliders.forEach(function (slider, index) {
      let mode = null;
      const sliderHtml = slider.innerHTML;
      
      const resizeHandler = () => {
        if (window.innerWidth > 767) {
          if (mode != 'desktop') {
            slider.innerHTML = sliderHtml;
            mode = 'desktop';
          }
        } else {
          if (mode != 'mobile') {
            new Swiper(slider, {
              loop: false,
              spaceBetween: 15,
              pagination: {
                el: '.swiper-pagination',
              },
            });
            mode = 'mobile';
          }
        }
      }
      resizeHandler();
      window.addEventListener('resize', resizeHandler);
      
    });
    
    
  }
  
  if (document.querySelector(".slider")) {
    const sliders = document.querySelectorAll(".slider");
    sliders.forEach(function (slider, index) {
      new Swiper(slider, {
        loop: false,
        spaceBetween: 0,
        pagination: {
          el: '.swiper-pagination',
        },
      });
    });
    
    
  }
}