export default function initTouchNav() {
  const body = document.querySelector('body');
  const holder = document.querySelector('.menu-list');
  const menuItems = holder.querySelectorAll('.has-dropdown');
  
  const activeClass = 'hover';
  const bodyActiveClass = 'menu-active';
  let currentIndex = -1;
  const isTouchDevice = /MSIE 10.*Touch/.test(navigator.userAgent) || 'ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch);
  menuItems.forEach(function (item, index) {
    const menulinks = item.querySelector('.has-dropdown-a');
    const backBtn = item.querySelector('.btn-back');
    menulinks.addEventListener('click', (event) => trackAction(index, item));
  });
  
  const trackAction = (index, item) => {
    if (isTouchDevice) {
      if (currentIndex != index) {
        event.preventDefault();
        for (let i = 0; i < menuItems.length; i++) {
          if (index != i) {
            menuItems[i].classList.remove(activeClass);
          }
        }
        item.classList.add(activeClass);
        body.classList.add(bodyActiveClass);
        currentIndex = index;
      }
    }
  };
  
  const backTrackAction = (item) => {
    event.preventDefault();
    for (let i = 0; i < menuItems.length; i++) {
      menuItems[i].classList.remove(activeClass);
    }
    currentIndex = -1;
    holder.classList.remove(activeClass);
    body.classList.remove(bodyActiveClass);
  };
}
