import {animatedScrollTo} from 'es6-scroll-to';

export default function initSmoothScroll() {
  const holders = document.querySelectorAll('.scroll');
  holders.forEach(function (holder, index) {
    const trackAction = (event) => {
      const attr = document.querySelector(holder.getAttribute('href'));
      event.preventDefault();
      animatedScrollTo({
        to: attr.offsetTop,
        duration: 800
      });
    };
    holder.addEventListener('click', trackAction);
    
    document.addEventListener('scroll', function(e) {
      if(window.scrollY > 0) {
        document.querySelector('.back-to-top').classList.add('show');
      } else {
        document.querySelector('.back-to-top').classList.remove('show');
      }
    });
  });
}
