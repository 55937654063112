import "./scripts/scripts";
import initSwiper from './components/swiper.js';
import initTab from './components/tab.js';
import initAccordion from './components/accordion.js';
import initOpenClose from './components/openClose.js';
import initMobileNav from './components/mobileNav.js';
import initTouchNav from './components/touchNav.js';
import initHasDropDown from './components/hasDropdown.js';
import initSmoothScroll from './components/smoothScroll.js';
import initValidator from './components/validator.js';

document.addEventListener("DOMContentLoaded", function() {
  initSwiper();
  initTab();
  initAccordion();
  initOpenClose();
  initMobileNav();
  initHasDropDown();
  initTouchNav();
  initSmoothScroll();
  initValidator();
});
