
export default function initOpenClose() {
  const activeClass = 'active';
  
  const OpenClose = (holder, Holderopener, Holderslide) => {
    const items = document.querySelectorAll(holder);
    items.forEach(function (item, index) {
      const opener = item.querySelector(Holderopener);
      const drop = item.querySelector(Holderslide);
      const dropHeight = drop.offsetHeight;
      drop.style.maxHeight = '0';
      opener.addEventListener('click', (event) => trackAction())
      const trackAction = () => {
        event.preventDefault();
        item.classList.toggle(activeClass);
        for (let i = 0; i < items.length; i++) {
          if (index != i) {
            items[i].classList.remove(activeClass);
          }
          checkDrop(items[i]);
        }
      }
      
      const checkDrop = (item) => {
        if (item.classList.contains(activeClass)) {
          item.querySelector(Holderslide).style.maxHeight = dropHeight + 'px';
        } else {
          item.querySelector(Holderslide).style.maxHeight = 0;
        }
      }
      checkDrop(item);
    });
  }
  
  OpenClose('.footer__item', '.footer__title', '.footer__slide');
  OpenClose('.language', '.language__opener', '.language__list');
  OpenClose('.life__item', '.life__title', '.life__slide');
  
}