
export default function initAccordion() {
  const holders = document.querySelectorAll(".accordion");
  const activeClass = 'active';
  
  holders.forEach(function (holder) {
    const items = [].slice.call(holder.children);
    
    items.forEach(function (item, index) {
      const opener = item.querySelector('.accordion__opener');
      const drop = item.querySelector('.accordion__slide');
      const dropHeight = drop.offsetHeight;
      drop.style.height = '0';
      opener.addEventListener('click', (event) => trackAction())
      const trackAction = () => {
        event.preventDefault();
        item.classList.toggle(activeClass);
        for (let i = 0; i < items.length; i++) {
          if (index != i) {
            items[i].classList.remove(activeClass);
          }
          checkDrop(items[i]);
        }
      }
      
      const checkDrop = (item) => {
        if (item.classList.contains(activeClass)) {
          item.querySelector('.accordion__slide, .dropdown').style.height = dropHeight + 'px';
        } else {
          item.querySelector('.accordion__slide, .dropdown').style.height = 0;
        }
      }
      checkDrop(item);
    });
    
  });
  
}