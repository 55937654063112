import validator from 'validator';


export default function initValidator() {
  const inputs  = document.querySelectorAll('input:not([type="submit"]), textarea');
  const successClass = 'form__group--success';
  const errorClass = 'form__group--error';
  
  inputs.forEach(function (input, index) {
    
    input.addEventListener('blur',  () => {
      if(input.getAttribute('data-required')) {
        if(!validator.isEmpty(input.value)) {
          input.closest('.form__group').classList.add(successClass);
          input.closest('.form__group').classList.remove(errorClass);
        } else {
          input.closest('.form__group').classList.add(errorClass);
          input.closest('.form__group').classList.remove(successClass);
        }
      }
      if(input.getAttribute('data-email')) {
        console.log(validator.isEmail(input.value));
        if(validator.isEmail(input.value)) {
           input.closest('.form__group').classList.add(successClass);
          input.closest('.form__group').classList.remove(errorClass);
        } else {
          input.closest('.form__group').classList.add(errorClass);
          input.closest('.form__group').classList.remove(successClass);
        }
      }
      
      if(input.getAttribute('data-tel')) {
        if(validator.isMobilePhone(input.value)) {
           input.closest('.form__group').classList.add(successClass);
          input.closest('.form__group').classList.remove(errorClass);
        } else {
          input.closest('.form__group').classList.add(errorClass);
          input.closest('.form__group').classList.remove(successClass);
        }
      }
    });
    input.addEventListener('focus',  () => {
         input.closest('.form__group').classList.add(successClass);
          input.closest('.form__group').classList.remove(errorClass);
    });
  });
  
  
}
